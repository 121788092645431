.app {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100vw;
}

/* SERVICES CONTAINER (LOGO / VIDEOS) */

.servicesContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  width: 40vh;
}

.logoContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.logo {
  width: 90%;
  height: 100%;
  object-fit: contain;
}

.logo.show {
  filter: invert(100%);
}

.servicesMedia {
  position: relative;
  width: 40vh;
  height: 40vh;
}

.hoverimg#merchflipbook,
.hoverimg#fklogo,
.hoverimg#consvid {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hoverimg#merchflipbook.show,
.hoverimg#fklogo.show,
.hoverimg#consvid.show {
  display: block;
}

/* MISSION CONTAINER */

.missionContainer {
  max-width: 50vw;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5vh;
}

.missionList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  list-style-type: disc;
  font-size: 2.5vh;
  font-family: 'Inter Tight', sans-serif;
  text-align: left;
}

.clickable#services:hover {
  cursor: pointer;
  color: #cf5854;
}

.missionStatement {
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
}

.missionContainer p {
  font-size: 4vh;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  line-height: 1;
  z-index: 2;
}

.missionContainer.invertText li {
  color: white;
}

.missionContainer span {
  font-weight: 600;
  font-style: italic;
}

.linksContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 10vh;
  max-height: 25vh;
  max-width: 50vw;
  z-index: 2;
}

.linksContainer a {
  font-family: 'Schibsted Grotesk', sans-serif;
  font-size: 4vh;
  font-weight: 500;
  color: black;
  text-decoration: none;
}

.linksContainer a:hover {
  color: black;
}

.linksContainer a:visited {
  color: black;
  font-weight: 500;
  text-decoration: none;
}

.linksContainer a:visited:hover {
  color: black;
}

.cdIcon {
  width: 100%;
  height: 100%;
}

.socialLogo {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.socialLogo svg {
  font-size: 7vh;
}

.socialLogo img {
  position: fixed;
  object-fit: contain;
  z-index: 1;
}

.socialLogo#cdIcon1 img {
  max-height: 20%;
  max-width: 20%;
}

.socialLogo#cdIcon2 img {
  max-height: 25%;
  max-width: 25%;
}

/* smaller mobile devices (smartphones) */
@media screen and (max-width: 1400px) and (orientation: portrait) {
  .app {
    height: 90vh;
  }

  .servicesContainer {
    height: 30vh;
    width: 30vh;
  }

  .servicesMedia {
    height: 30vh;
    width: 30vh;
  }

  .landingPage {
    row-gap: 5vh;
  }

  .missionContainer p {
    font-size: 2.5vh;
  }

  .missionContainer {
    max-width: 80vw;
  }

  .missionList {
    font-size: 2vh;
  }

  .missionContainer.invertText li {
    color: black;
  }

  .linksContainer {
    max-width: 90vw;
  }

  .linksContainer a {
    font-size: 3vh;
  }

  .socialLogo svg {
    font-size: 5vh;
  }
}

@media screen and (max-width: 1400px) and (orientation: landscape) {
  .landingPage {
    row-gap: 3vh;
  }

  .servicesContainer {
    height: 40vh;
    width: 40vh;
  }

  .servicesMedia {
    height: 40vh;
    width: 40vh;
  }

  .missionContainer {
    max-width: 50vw;
  }

  .missionContainer p {
    font-size: 3.5vh;
  }

  .missionContainer.invertText li {
    color: black;
  }

  .linksContainer a {
    font-size: 4vh;
  }

  .socialLogo svg {
    font-size: 10vh;
  }
}

/* bigger mobile devices (tablets) */

@media screen and (min-width: 500px) and (max-width: 1400px) and (min-height: 700px) and (max-height: 1400px) and (orientation: portrait) {
  .servicesContainer {
    height: 40vh;
    width: 40vh;
  }

  .servicesMedia {
    height: 40vh;
    width: 40vh;
  }

  .missionContainer {
    max-width: 70vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 1400px) and (min-height: 600px) and (max-height: 1400px) and (orientation: landscape) {
  .servicesContainer {
    height: 40vh;
    width: 40vh;
  }

  .servicesMedia {
    height: 40vh;
    width: 40vh;
  }

  .missionContainer {
    max-width: 70vw;
  }
}

@media screen and (min-width: 1400px) {
  .landingPage {
    row-gap: 5vh;
  }
}
