.hoverimg {
  display: none;
}

.hoverimg.show {
  display: block;
}

.hoverimg#cdvid.show {
  width: 100%;
  height: 100%;
}

/* smaller mobile devices (smartphones) */
@media screen and (max-width: 1400px) and (orientation: portrait) {
  .hoverimg#cd1.show {
    position: fixed;
    top: 30%;
    right: 5%;
    width: 15vh;
    height: 15vh;
  }

  .hoverimg#cd2.show {
    position: fixed;
    bottom: 30%;
    left: 5%;
    width: 20vh;
    height: 20vh;
  }

  .cdvidContainer {
    position: fixed;
    top: 5%;
    left: 10%;
    max-height: 20vh;
    max-width: 15vh;
    overflow: hidden;
    z-index: 1;
  }

  .playlistContainer {
    display: none;
  }

  .playlistContainer.show {
    display: none;
  }

  .hoverimg#bs1.show {
    position: fixed;
    top: 5%;
    right: 10%;
    width: 25vh;
    height: 20vh;
  }

  .hoverimg#bs2.show {
    display: none;
  }

  .hoverimg#bs3.show {
    position: fixed;
    top: 40%;
    left: 5%;
    width: 20vh;
    height: 20vh;
  }

  .hoverimg#bs4.show {
    position: fixed;
    bottom: 2%;
    right: 5%;
    width: 20vh;
    height: 20vh;
  }

  .hoverimg#bsvid.show {
    display: none;
  }

  .hoverimg#cons1.show {
    position: fixed;
    top: 2%;
    left: 5%;
    width: 15vh;
    height: 20vh;
    z-index: 9999;
  }

  .hoverimg#cons2.show {
    position: fixed;
    top: 38%;
    left: 7%;
    width: 10vh;
    height: 15vh;
  }

  .hoverimg#cons3.show {
    position: fixed;
    top: 5%;
    right: 3%;
    width: 15vh;
    height: 20vh;
  }

  .hoverimg#cons4.show {
    position: fixed;
    top: 40%;
    right: 5%;
    width: 15vh;
    height: 15vh;
    z-index: 4;
  }

  .hoverimg#cons5.show {
    display: none;
  }

  .hoverimg#cons6.show {
    position: fixed;
    bottom: 4%;
    left: 5%;
    width: 20vh;
    height: 25vh;
  }

  .hoverimg#cons7.show {
    position: fixed;
    bottom: 3%;
    right: 3%;
    width: 15vh;
    height: 15vh;
  }
}

@media screen and (max-width: 1400px) and (orientation: landscape) {
  .hoverimg#cd1.show {
    position: fixed;
    top: 15%;
    right: 12%;
    width: 40vh;
    height: 40vh;
  }

  .hoverimg#cd2.show {
    position: fixed;
    top: 25%;
    left: 10%;
    width: 40vh;
    height: 40vh;
  }

  .cdvidContainer {
    position: fixed;
    bottom: 5%;
    right: 5%;
    max-height: 35vh;
    max-width: 25vh;
    overflow: hidden;
    z-index: 1;
  }

  .playlistContainer {
    display: none;
  }

  .playlistContainer.show {
    display: none;
  }

  .hoverimg#bs1.show {
    position: fixed;
    top: 5%;
    left: 5%;
    width: 50vh;
    height: 40vh;
  }

  .hoverimg#bs2.show {
    position: fixed;
    bottom: 5%;
    right: 5%;
    width: 50vh;
    height: 40vh;
  }

  .hoverimg#bs3.show {
    position: fixed;
    top: 5%;
    right: 7.5%;
    width: 40vh;
    height: 40vh;
  }

  .hoverimg#bs4.show {
    position: fixed;
    bottom: 5%;
    left: 7.5%;
    width: 40vh;
    height: 40vh;
  }

  .hoverimg#bsvid.show {
    display: none;
  }

  .hoverimg#cons1.show {
    position: fixed;
    bottom: 10%;
    left: 10%;
    width: 30vh;
    height: 40vh;
    z-index: 2;
  }

  .hoverimg#cons2.show {
    position: fixed;
    top: 20%;
    left: 20%;
    width: 30vh;
    height: 40vh;
  }

  .hoverimg#cons3.show {
    position: fixed;
    top: 5%;
    left: 5%;
    width: 30vh;
    height: 40vh;
  }

  .hoverimg#cons4.show {
    position: fixed;
    top: 40%;
    right: 25%;
    width: 30vh;
    height: 30vh;
  }

  .hoverimg#cons5.show {
    position: fixed;
    top: 8%;
    right: 12%;
    width: 30vh;
    height: 40vh;
  }

  .hoverimg#cons6.show {
    display: none;
  }

  .hoverimg#cons7.show {
    position: fixed;
    bottom: 10%;
    right: 4%;
    width: 35vh;
    height: 40vh;
  }
}

@media screen and (min-width: 500px) and (max-width: 1400px) and (min-height: 700px) and (max-height: 1400px) and (orientation: portrait) {
  .hoverimg#cd1.show {
    position: fixed;
    top: 25%;
    right: 3%;
    width: 25vh;
    height: 25vh;
  }

  .hoverimg#cd2.show {
    position: fixed;
    top: 3%;
    left: 7%;
    width: 25vh;
    height: 25vh;
  }

  .cdvidContainer {
    position: fixed;
    top: auto;
    left: auto;
    bottom: 25%;
    left: 5%;
    max-height: 30vh;
    max-width: 20vh;
    overflow: hidden;
    z-index: 1;
  }

  .playlistContainer {
    display: none;
  }

  .playlistContainer.show {
    display: none;
  }

  .hoverimg#bs1.show {
    position: fixed;
    top: 5%;
    right: 5%;
    width: 30vh;
    height: 25vh;
  }

  .hoverimg#bs2.show {
    display: none;
  }

  .hoverimg#bs3.show {
    position: fixed;
    top: 40%;
    left: 5%;
    width: 30vh;
    height: 30vh;
  }

  .hoverimg#bs4.show {
    position: fixed;
    bottom: 2%;
    right: 15%;
    width: 20vh;
    height: 20vh;
  }

  .hoverimg#bsvid.show {
    display: none;
  }

  .hoverimg#cons1.show {
    position: fixed;
    top: 2%;
    left: 5%;
    width: 20vh;
    height: 30vh;
    z-index: 9999;
  }

  .hoverimg#cons2.show {
    position: fixed;
    top: 38%;
    left: 10%;
    width: 15vh;
    height: 20vh;
  }

  .hoverimg#cons3.show {
    position: fixed;
    top: 5%;
    right: 8%;
    width: 15vh;
    height: 20vh;
  }

  .hoverimg#cons4.show {
    position: fixed;
    top: 40%;
    right: 5%;
    width: 20vh;
    height: 20vh;
    z-index: 4;
  }

  .hoverimg#cons5.show {
    display: none;
  }

  .hoverimg#cons6.show {
    position: fixed;
    bottom: 4%;
    left: 5%;
    width: 20vh;
    height: 25vh;
  }

  .hoverimg#cons7.show {
    position: fixed;
    bottom: 3%;
    right: 3%;
    width: 15vh;
    height: 15vh;
  }
}

@media screen and (min-width: 500px) and (max-width: 1400px) and (min-height: 700px) and (max-height: 1400px) and (orientation: landscape) {
  .hoverimg#cd1.show {
    position: fixed;
    top: 8%;
    right: 8%;
    width: 30vh;
    height: 30vh;
  }

  .hoverimg#cd2.show {
    position: fixed;
    top: auto;
    left: auto;
    bottom: 10%;
    right: 3%;
    width: 30vh;
    height: 30vh;
  }

  .cdvidContainer {
    position: fixed;
    top: 25%;
    left: 5%;
    max-height: 50vh;
    max-width: 30vh;
    overflow: hidden;
    z-index: 1;
  }

  .playlistContainer {
    display: none;
  }

  .playlistContainer.show {
    display: none;
  }

  .hoverimg#bs1.show {
    position: fixed;
    top: 10%;
    left: 3%;
    width: 35vh;
    height: 25vh;
  }

  .hoverimg#bs2.show {
    position: fixed;
    bottom: 10%;
    right: 3%;
    width: 35vh;
    height: 25vh;
  }

  .hoverimg#bs3.show {
    position: fixed;
    top: 7%;
    right: 5%;
    width: 30vh;
    height: 30vh;
  }

  .hoverimg#bs4.show {
    position: fixed;
    bottom: 7%;
    left: 5%;
    width: 30vh;
    height: 30vh;
  }

  .hoverimg#bsvid.show {
    display: none;
  }

  .hoverimg#cons1.show {
    position: fixed;
    bottom: 10%;
    left: 10%;
    width: 20vh;
    height: 30vh;
    z-index: 2;
  }

  .hoverimg#cons2.show {
    position: fixed;
    top: 37%;
    left: 23%;
    width: 20vh;
    height: 30vh;
  }

  .hoverimg#cons3.show {
    position: fixed;
    top: 10%;
    left: 7%;
    width: 25vh;
    height: 30vh;
  }

  .hoverimg#cons4.show {
    position: fixed;
    top: 40%;
    right: 20%;
    width: 25vh;
    height: 25vh;
  }

  .hoverimg#cons5.show {
    position: fixed;
    top: 8%;
    right: 7%;
    width: 20vh;
    height: 30vh;
  }

  .hoverimg#cons6.show {
    display: none;
  }

  .hoverimg#cons7.show {
    position: fixed;
    bottom: 10%;
    right: 4%;
    width: 25vh;
    height: 30vh;
  }
}

@media screen and (min-width: 1400px) {
  .hoverimg#cd1.show {
    position: fixed;
    top: 3%;
    right: 15%;
    width: 40vh;
    height: 40vh;
  }

  .hoverimg#cd2.show {
    position: fixed;
    bottom: 10%;
    left: 5%;
    width: 40vh;
    height: 40vh;
  }

  .hoverimg#cd5.show {
    position: fixed;
    bottom: 5%;
    left: 5%;
    width: 40vh;
    height: 40vh;
  }

  .cdvidContainer {
    position: fixed;
    top: 5%;
    left: 15%;
    max-height: 40vh;
    max-width: 30vh;
    overflow: hidden;
    z-index: 1;
  }

  .playlistContainer {
    display: none;
  }

  .playlistContainer.show {
    display: inline-block;
    position: fixed;
    bottom: 5%;
    right: 3%;
    width: 50vh;
    max-height: 50vh;
    overflow: hidden;
    z-index: 1;
  }

  .aspectRatioKeeper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 50vh;
  }

  .playlistHoverMessage {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 550;
  }

  .playlist {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .hoverimg#bs1.show {
    position: fixed;
    top: 5%;
    left: 5%;
    width: 50vh;
    height: 40vh;
  }

  .hoverimg#bs2.show {
    position: fixed;
    bottom: 5%;
    right: 5%;
    width: 50vh;
    height: 40vh;
  }

  .hoverimg#bs3.show {
    position: fixed;
    top: 5%;
    right: 7.5%;
    width: 40vh;
    height: 40vh;
  }

  .hoverimg#bs4.show {
    position: fixed;
    bottom: 5%;
    left: 7.5%;
    width: 40vh;
    height: 40vh;
  }

  .hoverimg#bsvid.show {
    position: fixed;
    /* bottom: 20%;
  right: 40%; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -20%);
    width: 40vh;
    height: 40vh;
    z-index: 1;
  }

  .hoverimg#cons1.show {
    position: fixed;
    bottom: 10%;
    left: 10%;
    width: 30vh;
    height: 40vh;
    z-index: 2;
  }

  .hoverimg#cons2.show {
    position: fixed;
    top: 20%;
    left: 20%;
    width: 30vh;
    height: 40vh;
  }

  .hoverimg#cons3.show {
    position: fixed;
    top: 5%;
    left: 5%;
    width: 30vh;
    height: 40vh;
  }

  .hoverimg#cons4.show {
    position: fixed;
    top: 40%;
    right: 25%;
    width: 30vh;
    height: 30vh;
  }

  .hoverimg#cons5.show {
    position: fixed;
    top: 8%;
    right: 12%;
    width: 30vh;
    height: 40vh;
  }

  .hoverimg#cons6.show {
    position: fixed;
    bottom: 2%;
    right: 45%;
    width: 20vh;
    height: 25vh;
  }

  .hoverimg#cons7.show {
    position: fixed;
    bottom: 10%;
    right: 4%;
    width: 35vh;
    height: 40vh;
  }
}
